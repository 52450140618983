import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// import ReactPlayer from 'react-player/lazy';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ListItem } from '../../CustomStyles/Typography';

const Video = loadable(() => import('../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
    color: theme.workwaveBlue,
    fontSize: '2.125rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8219rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5625rem',
    },
  },
  subHeader: {
    fontWeight: 400,
    color: theme.workwaveBlue,
    fontSize: '1.25rem',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  img: {
    maxWidth: '817px',
    //maxHeight: '550px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '720px',
    },
  },
  button: {
    color: 'white',
    backgroundColor: '#002D5C',
    transition: 'all 0.35s ease !important',
    '&:hover': {
      opacity: '0.85 !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  shareText: {
    //marginBottom: '1rem',
    color: theme.workwaveBlue,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  share: {
    color: theme.darkGray,
    lineHeight: 1.5,
    fontSize: '0.938rem',
    fontWeight: 500,
    paddingRight: '10px',
  },
}));

export const MainFeature = ({
  featureHeader,
  _rawFeatureBody,
  videoVariant,
  featureImage,
  // svgVariant,
  thumbnailVideoUrl,
  fileDownload,
  downloadCTAText,
  shareText,
  socialAssets,
}) => {
  const classes = useStyles();
  const lg = useMediaQuery('(max-width: 1280px)');
  const md = useMediaQuery('(max-width: 960px)');
  const xs = useMediaQuery('(max-width: 481px)');

  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
      direction='row'
      style={{ padding: md ? '0 0 90px' : lg ? '0 0 100px' : '0 0 110px' }}
    >
      {!!_rawFeatureBody ? (
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='h2' className={classes.header}>
            {featureHeader}
          </Typography>
          <Grid
            item
            container
            direction='column'
            alignItems='flex-start'
            justifyContent='space-evenly'
          >
            {_rawFeatureBody.map((content, index) => (
              <PortableText
                key={index}
                content={content}
                // className={classes.text}
                serializers={{
                  h2: ({ children }) => (
                    <Typography
                      variant='h2'
                      style={{
                        lineHeight: 0.9,
                      }}
                    >
                      {children}
                    </Typography>
                  ),
                  normal: ({ children }) => (
                    <Typography
                      variant='body1'
                      style={{ color: '#4B5B69', fontFamily: 'Roboto' }}
                    >
                      {children}
                    </Typography>
                  ),
                  li: ({ children }) => (
                    <Grid item>
                      <ListItem>
                        <li>{children}</li>
                      </ListItem>
                    </Grid>
                  ),
                }}
              />
            ))}
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} md={8} container justifyContent='center'>
        {featureImage ? (
          <GatsbyImage
            image={featureImage?.asset?.gatsbyImageData}
            alt='chart'
            className={classes.img}
            imgStyle={{ objectFit: 'contain', transition: '.5s' }}
          />
        ) : // )
        videoVariant ? (
          <div className={classes.playerWrapper}>
            {/* <ReactPlayer
							url={videoVariant}
							className={classes.reactPlayer}
							height='100%'
							width='100%'
							controls={true}
						/> */}
            <Video
              url={videoVariant}
              height='100%'
              width='100%'
              controls={true}
            />
          </div>
        ) : // svgVariant ? (
        // 	<svg>
        // 		<div
        // 			style={{ maxWidth: '100%' }}
        // 			dangerouslySetInnerHTML={{ __html: svgVariant }}
        // 		/>
        // 	</svg>
        // ) :
        null}
      </Grid>
      {!!fileDownload ? (
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={8}
        >
          <a
            href={fileDownload.asset.url}
            target='_blank'
            aria-label='Opens in a new tab'
            style={{ textDecoration: 'none' }}
            rel='noopener noreferrer'
          >
            <Button
              variant='contained'
              size='large'
              className={classes.button}
              style={{
                marginLeft: '1rem',
                marginTop: md ? '2rem' : '3rem',
                padding: '16px 32px',
              }}
            >
              {downloadCTAText}
            </Button>
          </a>
        </Grid>
      ) : null}
      {!!shareText ? (
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={8}
        >
          <Typography variant='body1' className={classes.shareText}>
            {shareText}
          </Typography>
        </Grid>
      ) : null}
      {!!socialAssets.length ? (
        <Grid item container direction='row' xs={12} md={8}>
          <Grid
            container
            item
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            {!shareText ? (
              <Typography variant='body1' className={classes.share}>
                SHARE:
              </Typography>
            ) : null}

            {socialAssets.map((socialAsset) => (
              <a
                href={socialAsset.socialLink}
                target='_blank'
                aria-label='Opens in a new tab'
              >
                <FontAwesomeIcon
                  icon={['fab', socialAsset.faIcon]}
                  style={{
                    color: '#002D5C',
                    marginRight: '12px',
                  }}
                  size='2x'
                />
              </a>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
